import github_logo from '../assets/github-mark-white.svg'
import './Refs.css';

const Refs = () => {
  return (
    <div className='refs_container'>
      <p className='refs_text'>by optim</p>
      <a href='https://github.com/0ptim0' target='_blank'>
        <img className='refs_image' src={github_logo} alt='GitHub' />
      </a>
    </div>
  );
}

export default Refs;
