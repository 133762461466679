import React, { useState, useEffect } from 'react';
import chip_large from '../assets/chip_large.svg';
import chip_small from '../assets/chip_small.svg';
import './Chip.css';

const Chip = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getImageSource = () => {
    if (windowWidth < 1399) {
      return chip_small;
    } else {
      return chip_large;
    }
  };

  return (
    <div className='chip_container'>
      <img className='chip_image' src={getImageSource()} alt='chip' />
      <div className='chip_text'>
        <p><span style={{ color: '#00ff85' }}>components </span>
          is an AI-based search engine designed to save you time by allowing you to set custom filters and get design recommendations in chat format</p>
      </div>
    </div>
  );
}

export default Chip;
