import top_trace from '../assets/top_trace.svg';
import './Header.css';

const Header = () => {
  return (
    <div>
      <div className='header_container'>
        <img className='header_image' src={String(top_trace)} alt='top_trace' />
        <p className='header_components'>components</p>
      </div>
      <div className='header_text'>Intelligent Electronic Components Search Engine</div>
    </div>
  );
}

export default Header;
