import './App.css';

import Header from './components/Header';
import Chip from './components/Chip';
import Demo from './components/Demo';
import SignUp from './components/SignUp';
import Refs from './components/Refs';

const background = {
  backgroundColor: '#0A0C14',
  width: '100%',
  margin: 0,
  padding: 0,
  minHeight: '100vh',
};

const gradient = {
  position: 'absolute',
  display: 'block',
  backgroundImage: 'radial-gradient(circle, rgba(0, 255, 87, 0.1) 0%, rgba(0, 0, 0, 0.0) 50%)',
  width: '100%',
  height: '100%',
  margin: '0px auto',
};

function App() {
  return (
    <div className="App" style={background}>
      <div className='gradient' />
      <Header />
      <Chip />
      <Demo />
      <SignUp />
      <Refs />
    </div>
  );
}

export default App;
