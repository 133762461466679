import React, { useState, useEffect } from 'react';
import demo_large from '../assets/demo_large.png';
import demo_small from '../assets/demo_small.png';
import bottom_right from '../assets/bottom_right.svg';
import './Demo.css';

const Demo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getImageSource = () => {
    if (windowWidth < 1399) {
      return demo_small;
    } else {
      return demo_large;
    }
  };

  return (
    <div className='demo_container'>
      <img className='bottom_right_image' src={String(bottom_right)} alt='bottom_right' />
      <div className='demo_rectangle'/>
      <img className='demo_image' src={getImageSource()} alt='demo' />
      <p className='demo_header'><span style={{ color: '#00ff85' }}>How it works</span></p>
      <div className='demo_text'>
        <p>Enter component type</p>
        <p>Describe desirable characteristics</p>
        <p>Specify the area of application</p>
      </div>
    </div>
  );
}

export default Demo;
