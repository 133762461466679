import React, { useState } from 'react';
import bottom_left from '../assets/bottom_left.svg';
import './SignUp.css';

let email_submitted = false;
let quest_submitted = false;

const SignUp = () => {
  const [email, setEmail, data] = useState('');
  const [isQustVisible, setIsQustVisible] = useState(true);
  const [isEmailVisible, setIsEmailVisible] = useState(true);

  const handleSubmit = async (e) => {
    if (!email_submitted) {
      e.preventDefault();
      try {
        const response = await fetch('/submit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
        if (response.ok) {
          console.log('Email submitted successfully!');
          email_submitted = true;
          setIsEmailVisible(!isEmailVisible);
        }
      } catch (error) {
        console.error('Error submitting email:', error);
      }
    }
  };

  const handleYes = async (e) => {
    if (!quest_submitted) {
      e.preventDefault();
      try {
        const response = await fetch('/yes', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data }),
        });

        if (response.ok) {
          console.log('"Yes" sent successfully');
          quest_submitted = true;
          setIsQustVisible(!isQustVisible);
        } else {
          console.error('Failed to send "yes"');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleNo = async (e) => {
    if (!quest_submitted) {
      e.preventDefault();
      try {
        const response = await fetch('/no', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data }),
        });

        if (response.ok) {
          console.log('"No" sent successfully');
          quest_submitted = true;
          setIsQustVisible(!isQustVisible);
        } else {
          console.error('Failed to send "no"');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <div className='sign_up_container'>
      <img className='bottom_left_image' src={String(bottom_left)} alt='bottom_left' />
      {isQustVisible && <div className='sign_up_quest_rectangle'>
        <div className='sign_up_quest_text'>
          Do you think this service would make your development process easier?
        </div>
        <button className='sign_up_quest_button_yes' onClick={handleYes}>
          <p className='sign_up_quest_button_text'>Yes</p>
        </button>
        <button className='sign_up_quest_button_no' onClick={handleNo}>
          <p className='sign_up_quest_button_text'>No</p>
        </button>
      </div>
      }
      {!isQustVisible && <div className='sign_up_quest_rectangle'>
        <div className='sign_up_quest_text_cent'>
          Thanks for your opinion!
        </div>
      </div>
      }
      {isEmailVisible && <div className='sign_up_email_rectangle'>
        <div className='sign_up_email_text'>
          Feel free to share your email, and we'll keep you informed as soon as our product becomes available
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type='email'
            placeholder='Enter your email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='sign_up_email_form'
          />
          <button type='submit' className='sign_up_email_button'>
            <p className='sign_up_email_button_text'>Submit</p>
          </button>
        </form>
      </div>
      }
      {!isEmailVisible && <div className='sign_up_email_rectangle'>
        <div className='sign_up_email_text_cent'>
          Thanks for your contribution!
        </div>
      </div>
      }
    </div>
  );
}

export default SignUp;
